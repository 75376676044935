.config {
  width: 100%;
}
.confighead {
  width: 100%;
  margin: 0px;
  padding: 15px 0px 0px 0px;
  display: inline-flex;
}
.confighead .fa {
  font-size: 16px;
  color: #222;
  padding: 5px 10px;
}
.confighead h3 {
  font-size: 17px;
  color: #222;
  padding: 2px 5px;
}
.config .configchild{
  padding: 20px;
}
.config .configchild .configform {
  padding: 1% 2%;
  margin:.5% .5%;
  width: 99%;
  border:2px dashed #ccc;
  background-color:#fff ;
  border-radius: 12px;
  display: inline-block;
}
.config .configchild .configform label {
  margin: 0px 0% 0px 0%;
  padding-top: 15px;
}
.config .configchild .configform input {
  padding: 5px 10px;
  margin: 5px 0% 0px 0%;
  border-radius: 2px;
  border: 1px solid #ccc;
  outline: none;
}
.config .configchild .configform .boxes {
  display: inline-flex;
  width: 100%;
}
.config .configchild .configform .boxes label{
  width: 25%;
}
.config .configchild .configform .boxes .selectManual{
  width: 75%;
}
.config .configchild .configform textarea {
  padding: 5px 10px;
  margin: 10px 0% 0px 0%;
  outline: none;
  width: 70%;
  height: 15vh;
  border-radius: 2px;
  border: 1px solid #ccc;
}
.config .configchild {
  padding: 10px;
  margin: 0px 0% 10px 0%;
  width: 100%;
  border-radius: 2px;
}
@media screen and (max-width: 670px) {
  .config .configform {
    padding: 10px 0% 10px 0%;
    margin: 0px;
    width: 100%;
  }
  .config .confighead {
    width: 100%;
    margin: 0px;
    padding: 40px 0px 0px 10px;
    display: inline-flex;
  }
  .config .configform label {
    width: 40%;
    margin: 0px 0% 0px 5%;
  }
  .config .configform input {
    padding: 5px 10px;
    margin: 10px 0% 0px 0%;
    width: 50%;
    border-radius: 2px;
    border: 1px solid #ccc;
  }
}
