
  /* src/Popup.css */

/* Overlay for the popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other elements */
  }
  
  /* Popup content container */
  .popup-content {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    position: relative;
    width: 400px;
    max-width: 80%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    animation: fadeIn 0.3s ease-out;
  }
  .smlform{
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    gap:10;
    display: inline-block;
  }
  .smlform  input{
    padding: 5px 10px;
    border: 1px solid #ccc;
    color: #000; 
    border-radius: 4px;
    width: 100%;
  }
  .smlform button{
    padding: 5px 10px;
    background-color: orange;
    color: #fff;
    font-size: 16px;
    border: none;
    width: 100%;
    margin: 10px 0;
  }
  /* Close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Submenu list */
  .submenu {
    list-style-type: none;
    margin-top: 10px;
    width: 100%;
    padding: 0;
  }
  
  .submenu li {
    margin-bottom: 0px;
    width: 100%;
    border: 1px solid #ccc;
    color:#000;
  }
  
  .submenu li a {
    text-decoration: none;
    color: #333;
    padding: 8px 12px;
    display: block;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .submenu li a:hover {
    background-color: #f0f0f0;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
  }
  